import { useTranslation } from "next-i18next";
import { zeroAddress } from "viem";
import { Flex, FlexProps } from "@chakra-ui/react";
import { formatToSignificant, isAddressEqual } from "@looksrare/utils";
import { EthTokenIcon, Text, TokenYoloIcon } from "@looksrare/uikit";
import { FlipAnimationState, FlipSide, Flipper, FlipperStep } from "../../types";
import { isFlipTotalWin } from "../../utils";

interface HeaderTextProps extends FlexProps {
  flipAnimationState: FlipAnimationState;
  flipperStep: FlipperStep;
  singlePlayAmount?: Flipper["amountPerRoundWei"];
  singleFlipResult?: FlipSide;
  pickSide?: Flipper["pickSide"];
  flipResults?: Flipper["flipResults"];
  winningAmountWei?: Flipper["winningAmountWei"];
  currencyAddress?: Flipper["currency"];
}

type HeaderVariant = "win" | "loss" | "empty";

/**
 * HeaderText can display a few messages, based on the coinflip status and flip results.
 * "You Won {Amount}"
 * "Unlucky"
 * empty box
 */
export const HeaderText = ({
  flipAnimationState,
  flipperStep,
  pickSide,
  singlePlayAmount,
  singleFlipResult,
  flipResults,
  winningAmountWei,
  currencyAddress,
  ...props
}: HeaderTextProps) => {
  const { t } = useTranslation();
  const isSingleWin = !!singleFlipResult && !!pickSide && singleFlipResult === pickSide;
  const isTotalWin = !!flipResults && !!pickSide && isFlipTotalWin(flipResults, pickSide);

  const headerVariant: HeaderVariant = (() => {
    if (flipperStep === FlipperStep.FINAL_RESULTS) {
      return isTotalWin ? "win" : "loss";
    }

    if (
      flipAnimationState === FlipAnimationState.VIDEO_PLAYING ||
      flipperStep === FlipperStep.INPUT ||
      flipperStep === FlipperStep.GENERATING_RANDOMNESS
    ) {
      return "empty";
    }

    if (flipperStep === FlipperStep.FLIPPING) {
      // @note - While "flipping", we can show the flip animation or the flip result.
      // The condition above means we are displaying the flip result.
      return isSingleWin ? "win" : "loss";
    }

    return "empty";
  })();

  const winAmount = (() => {
    if (flipperStep === FlipperStep.FINAL_RESULTS && isTotalWin) {
      return winningAmountWei;
    }

    if (!singlePlayAmount) {
      return 0n;
    }
    // Single playAmount - 2% fee
    const singlePlayAmountBi = BigInt(Number(singlePlayAmount));
    const feeAmount = (singlePlayAmountBi * 2n) / 100n;
    return singlePlayAmountBi - feeAmount;
  })();

  if (headerVariant === "win" && !!winAmount) {
    const TokenIcon = isAddressEqual(currencyAddress, zeroAddress) ? EthTokenIcon : TokenYoloIcon;
    return (
      <Flex justifyContent="center" height="80px" alignItems="center" gap="10px" flexWrap="wrap" {...props}>
        <Text textStyle="display-03" fontWeight="bold" textTransform="uppercase" whiteSpace="nowrap">
          {t("flipper::You won")}
        </Text>
        <Flex alignItems="center" gap={1}>
          <TokenIcon boxSize={7} color="link-01" />
          <Text textStyle="display-03" fontWeight="bold" as="span" color="link-01">
            {formatToSignificant(winAmount, 4)}
          </Text>
          <Text textStyle="display-03" fontWeight="bold" textTransform="uppercase">
            !
          </Text>
        </Flex>
      </Flex>
    );
  }

  if (headerVariant === "loss") {
    return (
      <Flex justifyContent="center" minHeight="36px" alignItems="center" gap="10px" height="80px" {...props}>
        <Text textStyle="display-03" fontWeight="bold" textTransform="uppercase" whiteSpace="nowrap">
          {t("flipper::Unlucky")}
        </Text>
      </Flex>
    );
  }

  // empty placeholder div for spacing
  return <Flex height="80px" {...props} />;
};
