import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import {
  divideWeiByNumber,
  formatToSignificant,
  fromDecimals,
  multiplyWeiByNumber,
  toDecimals,
  useAddressesByNetwork,
  useEthBalance,
  useTokenBalance,
} from "@looksrare/utils";

interface InputValidationArgs {
  input: string;
  maxValueWei?: bigint;
  minValueWei?: bigint;
  maxPrecision?: number;
  currency?: string;
  numberOfFlips: number;
}

/**
 * Handles rounding the minimum value to the correct precision
 */
const getMinValueAtPrecision = (minValueWei: bigint, precision: number) => {
  const withPrecisionMinValueWei = toDecimals(String(1 / 10 ** precision));

  // if the wei value is less than the absolute minimum value, return the absolute minimum value for this precision
  if (minValueWei <= withPrecisionMinValueWei) {
    return withPrecisionMinValueWei;
  }

  const roundedToPrecision =
    parseFloat(fromDecimals(minValueWei, { significantDigits: precision })) + 1 / 10 ** precision;
  return toDecimals(String(roundedToPrecision));
};

// @note Borrowed from projects/yolo/components/depositAssets/inputValidation.ts

export const useInputValidation = ({
  input,
  maxValueWei,
  minValueWei,
  maxPrecision = 3,
  currency = "ETH",
  numberOfFlips,
}: InputValidationArgs) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { data: weiBalance } = useEthBalance(address!);
  const ethBalance = fromDecimals(weiBalance || 0);
  const addressesByNetwork = useAddressesByNetwork();
  const { data: tokenBalanceWei } = useTokenBalance({
    contractAddress: addressesByNetwork.YOLO,
    address: address!,
    queryOptions: { enabled: !!address },
  });
  const tokenBalance = fromDecimals(tokenBalanceWei || 0);

  // Formats minValueCurrency using minValueWei and maxPrecision
  const trueMinValueWei = getMinValueAtPrecision(minValueWei || 0n, maxPrecision);
  const minValueCurrency = fromDecimals(trueMinValueWei);

  const isEmptyInput = input === "";
  const currencySymbol = currency === "ETH" ? "ETH" : "YOLO";

  const gasBuffer = 0.001;
  const balanceRequired = parseFloat(input) * numberOfFlips + (currency === "ETH" ? gasBuffer : 0);
  const isBalanceSufficient = balanceRequired <= parseFloat(currency === "ETH" ? ethBalance : tokenBalance);
  const isBelowMinimum = parseFloat(input) < parseFloat(minValueCurrency);
  const inputDecimals = input.split(".")[1]?.length || 0;
  const isMultipleOfEntryValue = inputDecimals <= maxPrecision;

  const inputWei = toDecimals(input);
  const totalWagerWei = multiplyWeiByNumber(inputWei, numberOfFlips);
  const isAboveMaximum = !!maxValueWei && totalWagerWei > maxValueWei;

  const isValidInput = isBalanceSufficient && !isBelowMinimum && isMultipleOfEntryValue && !isAboveMaximum;

  const warning = (() => {
    if (isEmptyInput) {
      return "";
    }

    if (!isMultipleOfEntryValue) {
      return t("flipper::Must be a multiple of {{amount}} {{currencySymbol}}", {
        amount: String(1 / 10 ** maxPrecision),
        currencySymbol,
      });
    }

    if (isBelowMinimum) {
      return t("flipper::Minimum amount is {{amount}} {{currencySymbol}}", {
        amount: minValueCurrency,
        currencySymbol,
      });
    }

    if (isAboveMaximum) {
      const maxWagerPerRoundWei = divideWeiByNumber(maxValueWei, numberOfFlips);
      return t("flipper::Maximum amount is {{amount}} {{currencySymbol}}", {
        amount: formatToSignificant(maxWagerPerRoundWei!, 3),
        currencySymbol,
      });
    }

    if (!isBalanceSufficient) {
      return t("Insufficient Balance");
    }
  })();

  return { isValidInput, warning };
};
