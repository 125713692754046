import { useEffect, useRef } from "react";
import { usePreviousValue } from "@looksrare/utils";
import { AspectRatio, Box, BoxProps } from "@chakra-ui/react";
import { FlipSide } from "../../types";

interface FlipVideoProps extends BoxProps {
  isActive: boolean;
  fileName: string;
  flipIndex: number;
  onVideoComplete: () => void;
  isMuted: boolean;
}

const FlipVideo = ({ isActive, fileName, flipIndex, onVideoComplete, isMuted, ...props }: FlipVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const previousIsActive = usePreviousValue(isActive);
  const previousFlipIndex = usePreviousValue(flipIndex);
  const isNewFlipIndex = flipIndex !== previousFlipIndex;
  useEffect(() => {
    const { current: video } = videoRef;

    // Ensure the video starts at 0 when active
    if (!!video && ((!previousIsActive && isActive) || (isActive && isNewFlipIndex))) {
      video.currentTime = 0;
      video.play();
    }
  }, [videoRef, isActive, previousIsActive, isNewFlipIndex]);

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      opacity={isActive ? 1 : 0}
      pointerEvents="none"
      zIndex={isActive ? 1 : 0}
      {...props}
    >
      <AspectRatio ratio={1}>
        <Box
          as="video"
          ref={videoRef}
          height="100%"
          width="100%"
          controls={false}
          playsInline
          disablePictureInPicture
          onEnded={onVideoComplete}
          autoPlay
          muted={isMuted}
        >
          <Box as="source" src={`/videos/flipper/${fileName}.mp4`} type="video/mp4" />
        </Box>
      </AspectRatio>
    </Box>
  );
};

const getFilename = (startSide: FlipSide, endSide: FlipSide, isWin: boolean) => {
  const start = startSide === FlipSide.GOLD ? "gold" : "silver";
  const end = endSide === FlipSide.GOLD ? "gold" : "silver";
  const suffix = isWin ? "win" : "loss";
  return `${start}-to-${end}-${suffix}`;
};

interface FlipperVideoProps {
  isActive: boolean;
  flipIndex: number;
  onVideoComplete: () => void;
  isMuted: boolean;
}

export const GoldToGoldWinVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.GOLD, FlipSide.GOLD, true);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};

export const GoldToGoldLossVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.GOLD, FlipSide.GOLD, false);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};

export const GoldToSilverWinVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.GOLD, FlipSide.SILVER, true);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};
export const GoldToSilverLossVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.GOLD, FlipSide.SILVER, false);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};

export const SilverToGoldWinVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.SILVER, FlipSide.GOLD, true);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};

export const SilverToGoldLossVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.SILVER, FlipSide.GOLD, false);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};

export const SilverToSilverWinVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.SILVER, FlipSide.SILVER, true);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};
export const SilverToSilverLossVideo = ({ isActive, onVideoComplete, flipIndex, isMuted }: FlipperVideoProps) => {
  const fileName = getFilename(FlipSide.SILVER, FlipSide.SILVER, false);
  return (
    <FlipVideo
      isActive={isActive}
      fileName={fileName}
      onVideoComplete={onVideoComplete}
      flipIndex={flipIndex}
      isMuted={isMuted}
    />
  );
};
