import { useEffect } from "react";
import { Trans, useTranslation } from "next-i18next";
import { Box, Flex, Stack, StackProps, keyframes } from "@chakra-ui/react";
import { Text, useThrowConfettiFromCenter } from "@looksrare/uikit";
import { usePreviousValue } from "@looksrare/utils";
import { FlipSide, Flipper, FlipperStep } from "../../types";
import { useFlipperAnimation } from "../../hooks";
import { isFlipTotalWin } from "../../utils";
import { useFlipperConfig } from "../..";
import { CoinWithPadding } from "../Coin";
import { FlipResults } from "./FlipResults";
import { HeaderText } from "./HeaderText";
import { FlipAnimation } from "./FlipAnimation";

export const pulse = keyframes({
  "0%": {
    color: "link-01",
  },
  "50%": {
    opacity: 0.5,
  },
  "100%": {
    color: "link-01",
  },
});

interface FlipperGameUIProps extends StackProps {
  currentFlip: Flipper | null;
  flipperStep: FlipperStep;
  setFlipperStep: (status: FlipperStep) => void;
  goToResults: () => void;
  inputFlipCount?: number;
  currencyAddress?: Flipper["currency"];
}

export const FlipperGameUI = ({
  currentFlip,
  flipperStep,
  setFlipperStep,
  goToResults,
  inputFlipCount,
  currencyAddress,
  ...props
}: FlipperGameUIProps) => {
  const { t } = useTranslation();
  const { isMuted } = useFlipperConfig();
  const { numberOfRounds, flipResults, pickSide, amountPerRoundWei, numberOfRoundsPlayed, winningAmountWei } =
    currentFlip || {};

  const { flipIndex, flipAnimationState, onSingleFlipAnimationComplete } = useFlipperAnimation({
    flipId: currentFlip?.id,
    flipResults,
    flipperStep,
    setFlipperStep,
    goToResults,
  });

  const flipsRemaining = !!numberOfRounds ? numberOfRounds - flipIndex : 0;

  /**
   * Confetti handling
   */
  const previousFlipperStep = usePreviousValue(flipperStep);
  const isNowFinalResults =
    previousFlipperStep !== FlipperStep.FINAL_RESULTS && flipperStep === FlipperStep.FINAL_RESULTS;
  const throwConfetti = useThrowConfettiFromCenter();
  const isTotalWin = isFlipTotalWin(flipResults || [], pickSide || null);
  useEffect(() => {
    if (isNowFinalResults && isTotalWin) {
      throwConfetti();
    }
  }, [isNowFinalResults, isTotalWin, throwConfetti]);

  /**
   * Setting the central coin side.
   * On initial load it should be GOLD. When showing the results, it should be the last flip result.
   */
  const coinSide =
    (flipperStep === FlipperStep.FINAL_RESULTS ? flipResults?.[flipResults.length - 1] : FlipSide.GOLD) ||
    FlipSide.GOLD;

  return (
    <Stack spacing={4} px={4} {...props}>
      {/* Top Row Text */}
      <HeaderText
        flipAnimationState={flipAnimationState}
        flipperStep={flipperStep}
        pickSide={pickSide}
        singlePlayAmount={amountPerRoundWei}
        singleFlipResult={flipResults?.[flipIndex]}
        flipResults={flipResults}
        winningAmountWei={winningAmountWei}
        zIndex={1}
        currencyAddress={currencyAddress}
      />

      {/* Coin/Animation */}
      <Flex justifyContent="center" alignItems="center" px={2} gap={2} zIndex={0}>
        {/* Using divs on the left & right as spacers */}
        <Flex flex={1} />
        <Flex flex={1} flexBasis="45%" maxWidth="480px" justifyContent="center" alignItems="center">
          {flipperStep === FlipperStep.FLIPPING ? (
            <Box width="100%" position="relative">
              <FlipAnimation
                startSide={flipIndex === 0 ? FlipSide.GOLD : (flipResults || [])[flipIndex - 1]}
                endSide={flipResults?.[flipIndex] || FlipSide.GOLD}
                flipIndex={flipIndex}
                onVideoComplete={onSingleFlipAnimationComplete}
                width="100%"
                height="fit-content"
                borderRadius="circular"
                isMuted={isMuted}
                pickSide={pickSide!}
              />
            </Box>
          ) : (
            <CoinWithPadding variant={coinSide === FlipSide.GOLD ? "gold" : "silver"} opacity={1} p={0} />
          )}
        </Flex>
        <Flex flex={1} />
      </Flex>

      {/* FlipResults / Text Prompt */}
      <Flex width="100%" justifyContent="center" flexDirection="column" height="96px" gap={4} zIndex={1}>
        {(() => {
          if (flipperStep === FlipperStep.INPUT) {
            return (
              <Trans>
                <Text
                  textStyle="display-03"
                  fontWeight="bold"
                  textTransform="uppercase"
                  textAlign="center"
                  width="100%"
                >
                  Get ready to{" "}
                  <Text
                    as="span"
                    color="link-01"
                    textStyle="display-03"
                    fontWeight="bold"
                    textTransform="uppercase"
                    textAlign="center"
                    width="100%"
                  >
                    flip
                  </Text>{" "}
                  it
                </Text>
              </Trans>
            );
          }

          if (flipperStep === FlipperStep.GENERATING_RANDOMNESS) {
            return (
              <Text animation={`${pulse} 2s infinite`} color="link-01" bold textAlign="center">
                {t("flipper::Generating Randomness...")}
              </Text>
            );
          }

          if (flipperStep === FlipperStep.FLIPPING) {
            return (
              <Text textStyle="detail" color="text-03" textAlign="center">
                {t("flipper::Flips Remaining: {{flipsRemaining}}", { flipsRemaining })}
              </Text>
            );
          }

          if (flipperStep === FlipperStep.FINAL_RESULTS) {
            return (
              <Text color="link-01" textAlign="center">
                {t("flipper::Flipping Complete")}
              </Text>
            );
          }
        })()}

        <Flex justifyContent="center" alignItems="center">
          {flipperStep === FlipperStep.FLIPPING ? (
            <FlipResults
              flipperStep={flipperStep}
              numberOfRounds={numberOfRounds}
              numberOfRoundsPlayed={numberOfRoundsPlayed}
              flipResults={flipResults?.slice(0, flipIndex)}
              pickSide={pickSide}
            />
          ) : (
            <FlipResults
              flipperStep={flipperStep}
              numberOfRounds={flipperStep === FlipperStep.GENERATING_RANDOMNESS ? inputFlipCount : numberOfRounds}
              numberOfRoundsPlayed={numberOfRoundsPlayed}
              flipResults={flipResults}
              pickSide={pickSide}
            />
          )}
        </Flex>
      </Flex>
    </Stack>
  );
};
