import { useState } from "react";
import { Box, type StackProps, IconButton, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { type Currency } from "@looksrare/config";
import { ArrowLeftIcon, EthTokenIcon, Text, TokenYoloIcon, getStepStatus } from "@looksrare/uikit";
import type { BigIntish } from "@looksrare/utils";
import { InputPanelView } from "../types";
import { FlipperGameStore } from "../hooks/useFlipper";
import { ApproveTransferManagerStep, ApproveYoloStep, PlayStep } from "./TransactionSteps";

interface TransactionViewProps extends StackProps {
  currentCurrency: Currency;
  numberOfRounds: string;
  totalWagerEth: number;
  maxPayout: number;
  totalWagerYoloWei: BigIntish;
  flipCoin: FlipperGameStore["flipCoin"];
  setView: (view: InputPanelView) => void;
  onFlipComplete: () => void;
  onFlipError: () => void;
  setFlipperStep: FlipperGameStore["setFlipperStep"];
}

enum TransactionStep {
  ApproveTransferManager,
  ApproveERC20,
  Play,
}

export const TransactionView = ({
  currentCurrency,
  numberOfRounds,
  totalWagerEth,
  totalWagerYoloWei,
  maxPayout,
  setView,
  flipCoin,
  onFlipComplete,
  onFlipError,
  setFlipperStep,
  ...props
}: TransactionViewProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(
    currentCurrency === "ETH" ? TransactionStep.Play : TransactionStep.ApproveTransferManager
  );
  const TokenIcon = currentCurrency === "ETH" ? EthTokenIcon : TokenYoloIcon;

  return (
    <Stack
      width={{ base: "100%", md: "320px" }}
      flexShrink={0}
      spacing={0}
      pt={0}
      borderRadius="dialog"
      bg="rgba(255, 255, 255, 0.09)"
      alignSelf="flex-start"
      key="confirmEntry"
      {...props}
    >
      {/* Header */}
      <Flex p={4} gap={4} width="100%" alignItems="center">
        <IconButton
          aria-label="back"
          size="sm"
          variant="ghost"
          colorScheme="secondary"
          onClick={() => setView(InputPanelView.Default)}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Text bold>{t("flipper::Confirm Entry")}</Text>
      </Flex>
      {/* Body */}
      <Stack px={4} pt={6} pb={8} spacing={1}>
        {[
          { text: t("flipper::Total Flips"), value: numberOfRounds, isValueCurrency: false },
          { text: t("flipper::Total Entry"), value: totalWagerEth, isValueCurrency: true },
          { text: t("flipper::Max Payout"), value: maxPayout, isValueCurrency: true },
        ].map(({ text, value, isValueCurrency }) => (
          <Flex key={text} gap={1} alignItems="flex-end">
            <Text bold>{text}</Text>
            <Box
              flexGrow={1}
              alignSelf="flex-end"
              borderBottomWidth="1px"
              borderStyle="dotted"
              borderColor="border-01"
            />
            <Flex alignItems="center">
              <Text
                textStyle="detail"
                bold
                // adjust mr to line up with the TokenIcon
                mr={1}
              >
                {value}
              </Text>
              {isValueCurrency && <TokenIcon boxSize={4} />}
            </Flex>
          </Flex>
        ))}
        <Text mt={3} textStyle="detail">
          {t("flipper::Confirm your entry in your wallet app.")}
        </Text>
      </Stack>
      <Stack px={4} pb={4}>
        {currentCurrency === "YOLO" && (
          <>
            <ApproveTransferManagerStep
              status={getStepStatus(TransactionStep.ApproveTransferManager, step)}
              collapse={step !== TransactionStep.ApproveTransferManager}
              isCurrentStep={step === TransactionStep.ApproveTransferManager}
              onComplete={() => setStep(TransactionStep.ApproveERC20)}
              width="100%"
            />
            <ApproveYoloStep
              status={getStepStatus(TransactionStep.ApproveERC20, step)}
              collapse={step !== TransactionStep.ApproveERC20}
              isCurrentStep={step === TransactionStep.ApproveERC20}
              onComplete={() => setStep(TransactionStep.Play)}
              width="100%"
              amountWei={totalWagerYoloWei}
            />
          </>
        )}
        <PlayStep
          status={getStepStatus(TransactionStep.Play, step)}
          collapse={step !== TransactionStep.Play}
          isCurrentStep={step === TransactionStep.Play}
          onComplete={onFlipComplete}
          onError={onFlipError}
          flipCoin={flipCoin}
          width="100%"
          setFlipperStep={setFlipperStep}
        />
      </Stack>
    </Stack>
  );
};
