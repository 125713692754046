import { useEffect, useState } from "react";
import { Hash } from "viem";
import { BoxProps, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { usePublicClient } from "wagmi";
import { INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import {
  Text,
  BulkTransactionStep,
  BulkTransactionStepStatus,
  StepStatus,
  TransactionStepRow,
  StepErrorCta,
} from "@looksrare/uikit";
import { useHandleModalStep, useInvalidateEthBalance, useWalletClientIsReady, sleep } from "@looksrare/utils";
import { FlipperGameStore } from "../../hooks";
import { FlipperStep } from "../../types";

interface PlayStepProps extends BoxProps {
  status: StepStatus;
  collapse?: boolean;
  isCurrentStep: boolean;
  onComplete: () => void;
  onError: () => void;
  flipCoin: FlipperGameStore["flipCoin"];
  setFlipperStep: FlipperGameStore["setFlipperStep"];
}

export const PlayStep = ({
  status,
  collapse,
  isCurrentStep,
  onComplete,
  onError,
  setFlipperStep,
  flipCoin,
  ...props
}: PlayStepProps) => {
  const { isSignerReady } = useWalletClientIsReady();
  const publicClient = usePublicClient();
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState<Hash>();
  const invalidateEthBalance = useInvalidateEthBalance();

  const { handleSubmit, isRejected, isIdle, isAccepted } = useHandleModalStep({
    onSubmit: async () => {
      // Setting to INPUT clears the GameArea of any previous flip
      setFlipperStep(FlipperStep.INPUT);
      const hash = await flipCoin();
      if (!hash) {
        throw new Error("No transaction hash found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      setTransaction(hash);
      const receipt = await publicClient.waitForTransactionReceipt({ hash });
      if (receipt.status === "success") {
        invalidateEthBalance();
        // Wait for the transaction to be indexed
        await sleep(INDEXER_REFETCH_DELAY_MS);
        setTransaction(undefined);
      } else {
        throw new Error(`Entry failed. Transaction hash ${receipt.transactionHash}`);
      }
    },
    onSuccess: onComplete,
    onError,
  });

  const rowStatus = ((): BulkTransactionStepStatus => {
    if (isRejected) {
      return "error";
    }
    if (isIdle) {
      return "wait";
    }
    if (isAccepted) {
      return "done";
    }
    return "pending";
  })();

  useEffect(() => {
    if (isCurrentStep && isSignerReady) {
      handleSubmit({ callOnlyOnce: true });
    }
  }, [handleSubmit, isCurrentStep, isSignerReady]);

  const counterDisplay = "(1/1)";

  return (
    <BulkTransactionStep status={status} collapse={collapse} title={t("flipper::Flip Coin")} {...props}>
      <VStack spacing={4} alignItems="flex-start" width="100%">
        <Text textStyle="helper" bold color={isRejected ? "text-error" : "text-01"}>
          {isRejected
            ? t("flipper::You declined the transaction {{counterDisplay}}", { counterDisplay })
            : t("flipper::Confirm transaction in wallet {{counterDisplay}}", { counterDisplay })}
        </Text>
        <TransactionStepRow transactionHash={transaction} status={rowStatus} text={t("flipper::Send funds")} />
        {isRejected && <StepErrorCta onRetry={() => handleSubmit()} />}
      </VStack>
    </BulkTransactionStep>
  );
};
