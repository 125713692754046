import { Flipper } from "../types";

/**
 * Evaluates the set of flips in FlipResults to determine if the total wins are greater than the total losses.
 */
export const isFlipTotalWin = (flipResults: Flipper["flipResults"], pickSide: Flipper["pickSide"]) => {
  if (!flipResults) {
    return false;
  }
  const { winCount, lossCount } = flipResults.reduce(
    (acc, flipResult) => {
      if (flipResult === pickSide) {
        return { ...acc, winCount: acc.winCount + 1 };
      }
      return { ...acc, lossCount: acc.lossCount + 1 };
    },
    { winCount: 0, lossCount: 0 }
  );
  return winCount > lossCount;
};
