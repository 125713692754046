import { useEffect, useRef } from "react";

// @todo Move this to a shared utils package. Named this way to avoid conflicts with our other useInterval
// @link https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useDeclarativeInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void | undefined>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
