import { Flex, FlexProps, Image, ImageProps } from "@chakra-ui/react";
import { useBoxShadowPreset } from "@looksrare/uikit";

interface CoinProps extends FlexProps {
  variant: "gold" | "silver";
  isSelected?: boolean;
}
export const Coin = ({ variant, isSelected = false, ...props }: CoinProps) => {
  const boxShadowCardHover = useBoxShadowPreset("card-hover");

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      border="2px solid"
      borderRadius="circular"
      borderColor={isSelected ? "interactive-03" : "transparent"}
      opacity={isSelected ? 1 : 0.5}
      p="5px"
      cursor="pointer"
      _hover={{
        boxShadow: boxShadowCardHover,
        opacity: 1,
      }}
      {...props}
    >
      <Image alt={variant} src={`/images/flipper/${variant}2x.png`} width="100%" height="100%" />
    </Flex>
  );
};

interface CoinResultProps extends CoinProps {
  isWin: boolean;
}
export const CoinResult = ({ isWin, ...props }: CoinResultProps) => {
  return (
    <Coin
      opacity={1}
      _hover={{}}
      borderColor={isWin ? "support-success" : "support-error"}
      p={1}
      cursor="initial"
      {...props}
    />
  );
};

interface CoinWithPaddingProps extends ImageProps {
  variant: "gold" | "silver";
}
export const CoinWithPadding = ({ variant, ...props }: CoinWithPaddingProps) => {
  return (
    <Image alt={variant} src={`/images/flipper/${variant}-with-bg-2x.png`} width="100%" height="100%" {...props} />
  );
};
