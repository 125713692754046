import { BigIntish } from "@looksrare/utils";

export enum InputPanelView {
  Default,
  ConfirmEntry,
}

export enum FlipperContract {
  FLIPPER_V1_BLAST = "FLIPPER_V1_BLAST",
}

export enum FlipSide {
  GOLD = "GOLD",
  SILVER = "SILVER",
}

export enum FlipperStatus {
  DRAWING = "DRAWING",
  DRAWN = "DRAWN",
  CANCELLED = "CANCELLED",
  REFUNDED = "REFUNDED",
}

export interface FilterFlipperInput {
  contracts: FlipperContract[];
  player?: string;
}

interface FlipperUser {
  address: string;
  avatar: {
    image: {
      src: string;
      contentType: string;
    };
  };
}
export interface Flipper {
  id: string;
  player: FlipperUser;
  eventBlock: BigIntish;
  pickSide: FlipSide | null;
  numberOfRoundsPlayed: number;
  // @note playAmount = amount * numberOfRounds
  numberOfRounds: number;
  amountPerRoundWei: BigIntish;
  playAmountWei: BigIntish;
  currency: string;
  contract: FlipperContract;
  stopLossWei: BigIntish | null;
  stopGainWei: BigIntish | null;
  winningAmountWei: BigIntish | null;
  status: FlipperStatus;
  flipResults: FlipSide[] | null;
  createdAt: string;
  drawingTransactionHash: string | null;
  protocolFeeWei: BigIntish | null;
  liquidityPoolFeeWei: BigIntish | null;
  points: string | null;
  drawnTransactionHash: string | null;
}

/**
 * Game UI types.
 * These are separate from our API types
 */

export enum FlipperStep {
  // INPUT is the initial state and includes waiting for the tx
  INPUT = "INPUT",
  // GENERATING_RANDOMNESS is after the tx is confirmed, and we are waiting for a result
  GENERATING_RANDOMNESS = "GENERATING_RANDOMNESS",
  // FLIPPING is purely UI - we have all the flip results but we display one at a time
  FLIPPING = "FLIPPING",
  // FINAL_RESULTS is the final state, after all flips have been animated OR the user skips to results
  FINAL_RESULTS = "FINAL_RESULTS",
}

export enum FlipAnimationState {
  IDLE = "IDLE",
  // The flip animation is in progress
  VIDEO_PLAYING = "VIDEO_PLAYING",
}
