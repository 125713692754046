import { Box, Flex } from "@chakra-ui/react";
import times from "lodash/times";
import { Flipper, FlipSide, FlipperStep } from "../../types";
import { CoinResult } from "../Coin";

interface FlipResultsProps {
  numberOfRounds?: Flipper["numberOfRounds"];
  numberOfRoundsPlayed?: Flipper["numberOfRoundsPlayed"];
  flipResults?: Flipper["flipResults"];
  pickSide?: Flipper["pickSide"];
  flipperStep: FlipperStep;
}

export const FlipResults = ({
  flipperStep,
  numberOfRounds = 0,
  numberOfRoundsPlayed,
  flipResults = [],
  pickSide,
}: FlipResultsProps) => {
  if (flipperStep === FlipperStep.INPUT) {
    return null;
  }
  if (!flipResults) {
    return null;
  }
  return (
    <Flex alignItems="center" gap={2} width="100%" justifyContent="center">
      {times(numberOfRounds, (index) => {
        const flipResult = flipResults[index];
        return (
          <Flex key={index} width="36px" height="36px" alignItems="center" justifyContent="center">
            {(() => {
              if (
                flipResult &&
                (flipperStep === FlipperStep.FINAL_RESULTS || flipperStep === FlipperStep.FLIPPING) &&
                !!numberOfRoundsPlayed &&
                index < numberOfRoundsPlayed
              ) {
                return (
                  <CoinResult
                    opacity={1}
                    variant={flipResult === FlipSide.GOLD ? "gold" : "silver"}
                    isWin={flipResult === pickSide}
                    flex={1}
                  />
                );
              }
              return <Box borderRadius="circular" width="28px" height="28px" flex={1} bg="rgba(255, 255, 255, 0.04)" />;
            })()}
          </Flex>
        );
      })}
    </Flex>
  );
};
