import { gql } from "graphql-request";
import { FlipperSort } from "@looksrare/yolo-games-gql-typegen";
import { graphql, Pagination } from "@looksrare/utils";
import { Flipper, FlipperContract, FilterFlipperInput } from "./types";

const flipperFragment = gql`
  fragment FlipperFragment on Flipper {
    id
    player {
      address
      avatar {
        image {
          src
          contentType
        }
      }
    }
    eventBlock
    pickSide
    numberOfRounds
    numberOfRoundsPlayed
    amountPerRoundWei
    playAmountWei
    currency
    contract
    stopLossWei
    stopGainWei
    winningAmountWei
    status
    flipResults
    createdAt
    drawingTransactionHash
    protocolFeeWei
    liquidityPoolFeeWei
    points
    drawnTransactionHash
  }
`;

interface GetFlipperHistoryArgs {
  filter: FilterFlipperInput;
  pagination: Pagination;
  sort: FlipperSort;
  requestHeaders?: HeadersInit;
}

export const getFlipperHistory = async ({
  filter,
  pagination,
  sort = "NEWEST",
  requestHeaders,
}: GetFlipperHistoryArgs): Promise<Flipper[]> => {
  const query = gql`
    query GetFlipperHistory($filter: FilterFlipperInput!, $pagination: PaginationInput, $sort: FlipperSort) {
      coinFlips(filter: $filter, pagination: $pagination, sort: $sort) {
        ...FlipperFragment
      }
    }
    ${flipperFragment}
  `;

  const params = { filter, pagination, sort };
  const res = await graphql<{ coinFlips: Flipper[] }>({
    query,
    params,
    requestHeaders,
  });
  return res.coinFlips;
};

interface GetCurrentFlipArgs {
  contract: FlipperContract;
  address: string;
  requestHeaders?: HeadersInit;
}

export const getCurrentFlip = async ({
  contract,
  address,
  requestHeaders,
}: GetCurrentFlipArgs): Promise<Flipper | null> => {
  const query = gql`
    query GetUserCoinFlip($contract: FlipperContract!, $address: Address!) {
      user(address: $address) {
        currentFlip(contract: $contract) {
          ...FlipperFragment
        }
      }
    }
    ${flipperFragment}
  `;

  const res = await graphql<{ user: { currentFlip: Flipper | null } }>({
    query,
    params: { contract, address },
    requestHeaders,
  });
  return res.user.currentFlip;
};
