import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { Box, type StackProps, Flex, Stack } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { Button, EthTokenIcon, Text, TokenYoloIcon, useGameFallbackChainId } from "@looksrare/uikit";
import {
  useEthBalance,
  toDecimals,
  useAddressesByNetwork,
  useTokenBalance,
  multiplyWeiByNumber,
  formatNumberToLocale,
  getCurrencyDisplayDecimals,
} from "@looksrare/utils";
import { FlipperGameStore } from "../hooks/useFlipper";
import { FlipSide, FlipperStep, InputPanelView } from "../types";
import { useInputValidation } from "../utils";
import { useFlipperConfig } from "..";
import { PlayCTAWrapper } from "../../shared/components/PlayCTAWrapper";
import { type SetCurrencyHandler } from "../../shared/types";
import { Coin } from "./Coin";
import { WagerInput } from "./WagerInput";
import { NumberOfFlipsInput } from "./NumberOfFlipsInput";
import { TransactionView } from "./TransactionView";
import { AdvancedInputs } from "./AdvancedInputs";

interface InputPanelProps
  extends StackProps,
    Pick<
      FlipperGameStore,
      | "betAmount"
      | "flipperStep"
      | "numberOfRounds"
      | "totalWagerEth"
      | "selectedSide"
      | "maxNumberOfRounds"
      | "maxPayout"
      | "maxPlayAmountPerRoundWei"
      | "minPlayAmountPerRoundWei"
      | "setBetAmount"
      | "setFlipperStep"
      | "setSelectedSide"
      | "setNumberOfRounds"
      | "setMaxNumberOfRounds"
      | "flipCoin"
      | "stopOnProfitAmount"
      | "setStopOnProfitAmount"
      | "stopOnLossAmount"
      | "setStopOnLossAmount"
    > {
  onClickFlipCoin?: () => void;
  currentCurrency: Currency;
  setCurrentCurrency: SetCurrencyHandler;
}

/**
 * A note about the input rules.
 * There is a maxPlayAmountPerRound that is set by the contract. It is limiting the entryAmount * numberOfFlips.
 * Eg if maxPlayAmountPerRound is 0.1 ETH and we have 10 flips, the max value in the WagerInput would be 0.01 ETH.
 */
export const InputPanel = ({
  betAmount,
  currentCurrency,
  flipperStep,
  numberOfRounds,
  totalWagerEth,
  selectedSide,
  maxNumberOfRounds = 10,
  maxPayout,
  maxPlayAmountPerRoundWei,
  minPlayAmountPerRoundWei,
  flipCoin,
  setBetAmount,
  setCurrentCurrency,
  setFlipperStep,
  setSelectedSide,
  setNumberOfRounds,
  setMaxNumberOfRounds,
  setStopOnLossAmount,
  setStopOnProfitAmount,
  stopOnLossAmount,
  stopOnProfitAmount,
  ...props
}: InputPanelProps) => {
  const { t } = useTranslation();
  const { isWalletBlocked } = useFlipperConfig();
  const [view, setView] = useState(InputPanelView.Default);

  // @note This can be removed after launch. Teaser state will be true for ~24 hours only.
  const isGameInTeaserState = maxPlayAmountPerRoundWei === 0n;

  const { address, chain } = useAccount();
  const fallbackChainId = useGameFallbackChainId();

  const { data: balanceInWei } = useEthBalance(address!, { enabled: !!address }, chain?.id || fallbackChainId);
  const addressesByNetwork = useAddressesByNetwork();
  const { data: tokenBalanceWei } = useTokenBalance({
    contractAddress: addressesByNetwork.YOLO!,
    address: address!,
    queryOptions: { enabled: !!address },
  });

  const totalBetAmountWei = multiplyWeiByNumber(toDecimals(betAmount), Number(numberOfRounds));

  const isInsufficientEthBalance = !!(balanceInWei && balanceInWei < totalBetAmountWei);
  const isInsufficientYoloTokenBalance = !!(tokenBalanceWei && tokenBalanceWei < totalBetAmountWei);
  const isInsufficientBalance = currentCurrency === "ETH" ? isInsufficientEthBalance : isInsufficientYoloTokenBalance;
  const isPlayAmountPerRoundInputExceeded =
    !!maxPlayAmountPerRoundWei && toDecimals(betAmount) > maxPlayAmountPerRoundWei;

  const { isValidInput, warning } = useInputValidation({
    input: betAmount,
    currency: currentCurrency,
    maxValueWei: maxPlayAmountPerRoundWei,
    minValueWei: minPlayAmountPerRoundWei,
    numberOfFlips: Number(numberOfRounds || "1"),
    maxPrecision: 3,
  });

  const isAboveMaxNumberOfFlips = !!numberOfRounds && !!maxNumberOfRounds && Number(numberOfRounds) > maxNumberOfRounds;

  const TokenIcon = currentCurrency === "ETH" ? EthTokenIcon : TokenYoloIcon;
  const currencySymbol = currentCurrency === "ETH" ? "ETH" : "YOLO";
  const currencyAddress = addressesByNetwork[currentCurrency];

  const onFlipComplete = () => {
    // Update Game UI status
    setFlipperStep(FlipperStep.GENERATING_RANDOMNESS);

    // Update the InputPanel view
    setView(InputPanelView.Default);
  };
  const onFlipError = () => {
    setFlipperStep(FlipperStep.INPUT);
  };

  // @note The "Flip Coin" / "Add Funds" action button can appear in a few locations
  const defaultViewActionButton = (() => {
    const isMaxNumberOfRoundsExceeded = Number(numberOfRounds) > maxNumberOfRounds;
    const isDisabled =
      !isValidInput ||
      isPlayAmountPerRoundInputExceeded ||
      isMaxNumberOfRoundsExceeded ||
      !Number(betAmount) ||
      !Number(numberOfRounds);

    return (
      <PlayCTAWrapper
        isComingSoon={isGameInTeaserState}
        isWalletBlocked={isWalletBlocked}
        hasInsufficientBalance={isInsufficientBalance}
        canSkip={flipperStep === FlipperStep.FLIPPING}
        onSkip={() => setFlipperStep(FlipperStep.FINAL_RESULTS)}
        currencyAddress={currencyAddress}
        numberOfRounds={Number(numberOfRounds)}
        amountPerRoundWei={toDecimals(betAmount)}
      >
        <Button
          colorScheme="brand"
          isLoading={flipperStep === FlipperStep.GENERATING_RANDOMNESS}
          onClick={() => setView(InputPanelView.ConfirmEntry)}
          isDisabled={isDisabled}
        >
          {Number(numberOfRounds) <= 1
            ? t("flipper::Flip Coin")
            : t("flipper::{{numberOfRounds}} Flips", { numberOfRounds })}
          &nbsp;&middot; {formatNumberToLocale(Number(betAmount), 0, 3)} {currencySymbol}
        </Button>
      </PlayCTAWrapper>
    );
  })();

  if (view === InputPanelView.ConfirmEntry) {
    return (
      <TransactionView
        currentCurrency={currentCurrency}
        numberOfRounds={numberOfRounds}
        totalWagerEth={totalWagerEth}
        maxPayout={maxPayout}
        setView={setView}
        onFlipComplete={onFlipComplete}
        onFlipError={onFlipError}
        flipCoin={flipCoin}
        totalWagerYoloWei={currentCurrency === "ETH" ? 0n : toDecimals(betAmount) * BigInt(numberOfRounds)}
        setFlipperStep={setFlipperStep}
        {...props}
      />
    );
  }

  const wagerInputWarning = (() => {
    if (isGameInTeaserState) {
      return;
    }
    if (isInsufficientBalance) {
      return t("flipper::Insufficient Balance");
    }
    return warning;
  })();

  const numberOfFlipsInputWarning = (() => {
    if (isGameInTeaserState) {
      return;
    }
    if (isAboveMaxNumberOfFlips) {
      return t("flipper::Max Flips: {{maxFlips}}", { maxFlips: maxNumberOfRounds });
    }
  })();

  return (
    <Stack
      width={{ base: "100%", md: "320px" }}
      flexShrink={0}
      spacing={8}
      p={6}
      borderRadius="dialog"
      bg="rgba(255, 255, 255, 0.09)"
      alignSelf="flex-start"
      {...props}
    >
      {/* Inputs */}
      <Stack spacing={4}>
        <Flex gap={4}>
          <Stack spacing={2} flexBasis={{ base: "50%", md: "100%" }}>
            <Text textStyle="detail" bold color="text-03">
              {t("flipper::Pick Side")}
            </Text>
            <Flex width="100%" justifyContent="space-between" gap={2}>
              <Flex flex={1} alignItems="center" justifyContent="center">
                <Coin
                  width="100%"
                  height="100%"
                  variant="gold"
                  isSelected={selectedSide === FlipSide.GOLD}
                  onClick={() => {
                    if ([FlipperStep.FLIPPING, FlipperStep.GENERATING_RANDOMNESS].includes(flipperStep)) {
                      return;
                    }
                    setSelectedSide(FlipSide.GOLD);
                  }}
                />
              </Flex>
              <Flex flex={1} alignItems="center" justifyContent="center">
                <Coin
                  width="100%"
                  height="100%"
                  variant="silver"
                  isSelected={selectedSide === FlipSide.SILVER}
                  onClick={() => {
                    if ([FlipperStep.FLIPPING, FlipperStep.GENERATING_RANDOMNESS].includes(flipperStep)) {
                      return;
                    }
                    setSelectedSide(FlipSide.SILVER);
                  }}
                />
              </Flex>
            </Flex>
          </Stack>
          <Flex flexBasis="50%" alignItems="flex-end" display={{ base: "flex", md: "none" }}>
            {defaultViewActionButton}
          </Flex>
        </Flex>

        <WagerInput
          currentCurrency={currentCurrency}
          setCurrentCurrency={setCurrentCurrency}
          betAmount={betAmount}
          maxBetAmountWei={maxPlayAmountPerRoundWei}
          setBetAmount={setBetAmount}
          inputWarning={wagerInputWarning}
          numberOfFlips={Number(numberOfRounds || "1")}
        />

        <NumberOfFlipsInput
          maxNumberOfRounds={maxNumberOfRounds}
          numberOfRounds={numberOfRounds}
          onClickMax={setMaxNumberOfRounds}
          setNumberOfRounds={setNumberOfRounds}
          inputWarning={numberOfFlipsInputWarning}
        />
        <AdvancedInputs
          currencySymbol={currentCurrency}
          stopOnProfitAmount={stopOnProfitAmount}
          setStopOnProfitAmount={setStopOnProfitAmount}
          stopOnLossAmount={stopOnLossAmount}
          setStopOnLossAmount={setStopOnLossAmount}
        />
      </Stack>

      {/* Button */}
      <Flex display={{ base: "none", md: "flex" }}>{defaultViewActionButton}</Flex>

      {/* Payout details */}
      <Stack spacing={1}>
        {[
          {
            text: t("Total Entry"),
            value: formatNumberToLocale(totalWagerEth, 0, getCurrencyDisplayDecimals(currentCurrency)),
          },
          {
            text: t("Max Payout"),
            value: formatNumberToLocale(maxPayout, 0, getCurrencyDisplayDecimals(currentCurrency)),
          },
        ].map(({ text, value }) => (
          <Flex key={text} gap={1} alignItems="center">
            <Text textStyle="detail" color="text-03">
              {text}
            </Text>
            <Box flexGrow={1} height="100%" borderBottomWidth="1px" borderStyle="dotted" borderColor="border-01" />
            <Flex alignItems="center" gap={1}>
              <Text textStyle="detail" bold>
                {value}
              </Text>
              <TokenIcon boxSize={4} />
            </Flex>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};
