import { AspectRatio, Box, BoxProps } from "@chakra-ui/react";
import { FlipSide } from "../../types";
import {
  GoldToGoldWinVideo,
  GoldToGoldLossVideo,
  GoldToSilverWinVideo,
  GoldToSilverLossVideo,
  SilverToGoldWinVideo,
  SilverToGoldLossVideo,
  SilverToSilverWinVideo,
  SilverToSilverLossVideo,
} from "./videos";

interface FlipAnimationProps extends BoxProps {
  startSide: FlipSide;
  endSide: FlipSide;
  pickSide: FlipSide;
  flipIndex: number;
  onVideoComplete?: () => void;
  isMuted?: boolean;
}
export const FlipAnimation = ({
  startSide,
  endSide,
  pickSide,
  flipIndex,
  onVideoComplete,
  isMuted = false,
  ...props
}: FlipAnimationProps) => {
  const isWin = endSide === pickSide;

  return (
    <AspectRatio ratio={1} width="100%">
      <Box position="relative" backgroundSize="cover" backgroundRepeat="no-repeat" {...props}>
        <GoldToGoldWinVideo
          isActive={startSide === FlipSide.GOLD && endSide === FlipSide.GOLD && isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />

        <GoldToGoldLossVideo
          isActive={startSide === FlipSide.GOLD && endSide === FlipSide.GOLD && !isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
        <GoldToSilverWinVideo
          isActive={startSide === FlipSide.GOLD && endSide === FlipSide.SILVER && isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
        <GoldToSilverLossVideo
          isActive={startSide === FlipSide.GOLD && endSide === FlipSide.SILVER && !isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
        <SilverToGoldWinVideo
          isActive={startSide === FlipSide.SILVER && endSide === FlipSide.GOLD && isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
        <SilverToGoldLossVideo
          isActive={startSide === FlipSide.SILVER && endSide === FlipSide.GOLD && !isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
        <SilverToSilverWinVideo
          isActive={startSide === FlipSide.SILVER && endSide === FlipSide.SILVER && isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
        <SilverToSilverLossVideo
          isActive={startSide === FlipSide.SILVER && endSide === FlipSide.SILVER && !isWin}
          onVideoComplete={() => onVideoComplete?.()}
          flipIndex={flipIndex}
          isMuted={isMuted}
        />
      </Box>
    </AspectRatio>
  );
};
