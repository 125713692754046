import { FlexProps, Flex } from "@chakra-ui/react";
import { useFlipperGameStore } from "../hooks/useFlipper";
import { FlipperStep } from "../types";
import { InputPanel } from "./InputPanel";
import { FlipperGameUI } from "./FlipperGameUI/FlipperGameUI";

export const GameArea = (props: FlexProps) => {
  const {
    betAmount,
    currentCurrency,
    selectedSide,
    numberOfRounds,
    totalWagerEth,
    maxNumberOfRounds,
    maxPayout,
    maxPlayAmountPerRoundWei,
    minPlayAmountPerRoundWei,
    stopOnProfitAmount,
    stopOnLossAmount,
    setBetAmount,
    setCurrentCurrency,
    setFlipperStep,
    setSelectedSide,
    setNumberOfRounds,
    setMaxNumberOfRounds,
    flipCoin,
    currentFlip,
    flipperStep,
    setStopOnProfitAmount,
    setStopOnLossAmount,
  } = useFlipperGameStore();

  const bgImageUrl = `url('/images/flipper/flipper-bg-2x.png')`;

  return (
    <Flex
      width="100%"
      borderRadius="dialog"
      bg="#030B19"
      justifyContent="center"
      backgroundImage={bgImageUrl}
      backgroundSize="100% auto"
      backgroundRepeat="repeat"
      {...props}
    >
      <Flex
        gap={5}
        p={{ base: 4, lg: 8 }}
        width="100%"
        maxWidth="1600px"
        alignItems="center"
        height="100%"
        flexDirection={{ base: "column", md: "row" }}
      >
        <FlipperGameUI
          flex={1}
          height="100%"
          justifyContent="center"
          minWidth={0}
          currentFlip={currentFlip}
          flipperStep={flipperStep}
          goToResults={() => {
            setFlipperStep(FlipperStep.FINAL_RESULTS);
          }}
          setFlipperStep={setFlipperStep}
          currencyAddress={currentFlip?.currency}
        />
        <InputPanel
          width={{ base: "100%", md: "320px" }}
          backdropFilter="auto"
          backdropBlur="8px"
          flexShrink={0}
          alignSelf="flex-start"
          betAmount={betAmount}
          selectedSide={selectedSide}
          numberOfRounds={numberOfRounds}
          totalWagerEth={totalWagerEth}
          maxNumberOfRounds={maxNumberOfRounds}
          maxPlayAmountPerRoundWei={maxPlayAmountPerRoundWei}
          minPlayAmountPerRoundWei={minPlayAmountPerRoundWei}
          maxPayout={maxPayout}
          setBetAmount={setBetAmount}
          setFlipperStep={setFlipperStep}
          setSelectedSide={setSelectedSide}
          setNumberOfRounds={setNumberOfRounds}
          setMaxNumberOfRounds={setMaxNumberOfRounds}
          flipCoin={flipCoin}
          flipperStep={flipperStep}
          currentCurrency={currentCurrency}
          setCurrentCurrency={setCurrentCurrency}
          stopOnProfitAmount={stopOnProfitAmount}
          setStopOnProfitAmount={setStopOnProfitAmount}
          stopOnLossAmount={stopOnLossAmount}
          setStopOnLossAmount={setStopOnLossAmount}
        />
      </Flex>
    </Flex>
  );
};
