import { useCallback, useEffect, useState } from "react";
import { useAccount, usePublicClient, useWalletClient, useReadContract } from "wagmi";
import { type Address, encodeFunctionData } from "viem";
import last from "lodash/last";
import { UseQueryResult, useQuery, useQueryClient, useInfiniteQuery, InfiniteData } from "@tanstack/react-query";
import {
  Pagination,
  RQueryOptions,
  multiplyWeiByNumber,
  sleep,
  toDecimals,
  useAddressesByNetwork,
  usePreviousValue,
  useGlobalStore,
  DataLayerEventNames,
} from "@looksrare/utils";
import { type Currency, CLASSIC_GAMES_GAS_BUFFER, FlipperAbi, GameConfigManagerAbi } from "@looksrare/config";
import { isHash, isHashEqual } from "@looksrare/utils";
import {
  Flipper,
  FlipperContract,
  FilterFlipperInput,
  FlipSide,
  FlipperStep,
  FlipperStatus,
  FlipAnimationState,
} from "../types";
import { useDeclarativeInterval } from "../utils";
import { getFlipperHistory, getCurrentFlip } from "../queries";
import { useGetVrfParams } from "../../shared/network/contract/read/hooks";
import { ClassicGamesHistorySort, type SetCurrencyHandler } from "../../shared/types";
import { getClassicGamesGraphqlSort, getLastUsedCurrency, setLastUsedCurrency } from "../../shared/utils";
import { useFlipperConfig } from "../config";
import { useSendGameEntryAnalyticsEvent } from "../../shared/analytics";

/**
 * Returns the latest coin flip for a user. This can be in progress or completed.
 * Use the status field to determine the state.
 */
export const useCurrentCoinFlip = (
  contract: FlipperContract = FlipperContract.FLIPPER_V1_BLAST,
  options?: RQueryOptions<Flipper | null>
): UseQueryResult<Flipper | null, unknown> => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ["currentCoinFlip", address, contract],
    queryFn: async () => getCurrentFlip({ contract, address: address! }),
    enabled: !!address,
    ...options,
  });
};

const FLIPPER_PAGINATION_FIRST = 15;
const getNextPageParam = (lastPage: Flipper[]): Pagination | undefined => {
  if (lastPage.length < FLIPPER_PAGINATION_FIRST) {
    // No more data to fetch
    return undefined;
  }
  const lastFlip = last(lastPage);
  const cursor = lastFlip?.id;
  return { first: FLIPPER_PAGINATION_FIRST, cursor };
};

export interface FlipperGameStore {
  betAmount: string;
  currentCurrency: Currency;
  currentFlip: Flipper | null;
  flipperStep: FlipperStep;
  maxNumberOfRounds?: number;
  maxPayout: number;
  maxPlayAmountPerRoundWei?: bigint;
  minPlayAmountPerRoundWei?: bigint;
  numberOfRounds: string;
  selectedSide: FlipSide;
  stopOnLossAmount: string;
  stopOnProfitAmount: string;
  totalWagerEth: number;
  flipCoin: () => Promise<Address | void>;
  setBetAmount: (amount: string) => void;
  setCurrentCurrency: SetCurrencyHandler;
  setFlipperStep: (status: FlipperStep) => void;
  setMaxNumberOfRounds: () => void;
  setNumberOfRounds: (numberOfRounds: string) => void;
  setSelectedSide: (side: FlipSide) => void;
  setStopOnLossAmount: (amount: string) => void;
  setStopOnProfitAmount: (amount: string) => void;
}

/**
 * Primary hook to manage flipper game data & state.
 * Progress advances through FlipperSteps.
 */
export const useFlipperGameStore = (): FlipperGameStore => {
  const { supportedCurrencies } = useFlipperConfig();
  const [selectedSide, setSelectedSide] = useState(FlipSide.GOLD);
  const [currentCurrency, setCurrentCurrency] = useState<Currency>(
    getLastUsedCurrency({
      game: "flipper",
      fallback: "ETH",
      supportedCurrencies,
    })
  );
  const [betAmount, setBetAmount] = useState("0.01");
  const [stopOnLossAmount, setStopOnLossAmount] = useState("");
  const [stopOnProfitAmount, setStopOnProfitAmount] = useState("");
  const [numberOfRounds, setNumberOfRounds] = useState("1");
  const [flipperStep, setFlipperStep] = useState(FlipperStep.INPUT);
  const totalWagerEth = Number(betAmount) * Number(numberOfRounds);
  const queryClient = useQueryClient();
  const previousFlipperStep = usePreviousValue(flipperStep);

  const [transactionHash, setTransactionHash] = useState<Address>();

  /**
   * There is a 2% fee on the winnings, so the max payout is 1.96x the total wager.
   * @todo-flipper - This operation should use BigInts to avoid precision issues.
   */
  const maxPayout = totalWagerEth * 1.96;

  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const addressesByNetwork = useAddressesByNetwork();
  const sendEntryAnalyticsEvent = useSendGameEntryAnalyticsEvent(DataLayerEventNames.FLIPPER_DEPOSIT);

  const getVrfParams = useGetVrfParams();
  const { data: maxPlayAmountPerRound, refetch: refetchMaxPerRound } = useReadContract({
    address: addressesByNetwork.FLIPPER,
    abi: FlipperAbi,
    functionName: "maxPlayAmountPerGame",
    args: [currentCurrency === "ETH" ? addressesByNetwork.ETH : addressesByNetwork.YOLO],
  });

  const { data: minPlayAmountPerRound, refetch: refetchMinPerRound } = useReadContract({
    address: addressesByNetwork.FLIPPER,
    abi: FlipperAbi,
    functionName: "minPlayAmountPerGame",
    args: [currentCurrency === "ETH" ? addressesByNetwork.ETH : addressesByNetwork.YOLO],
  });

  /**
   * After any reset to INPUT, or when reaching FINAL_RESULTS, make sure the maxPlayAmountPerRound is up to date
   */
  const isTransitionedToInput = previousFlipperStep !== FlipperStep.INPUT && flipperStep === FlipperStep.INPUT;
  const isTransitionedToFinalResults =
    previousFlipperStep !== FlipperStep.FINAL_RESULTS && flipperStep === FlipperStep.FINAL_RESULTS;
  const shouldRefetchMaxPerRound = isTransitionedToInput || isTransitionedToFinalResults;
  useEffect(() => {
    if (shouldRefetchMaxPerRound) {
      refetchMaxPerRound();
      refetchMinPerRound();
    }
  }, [refetchMaxPerRound, refetchMinPerRound, shouldRefetchMaxPerRound]);

  const { data: maxNumberOfRounds } = useReadContract({
    address: addressesByNetwork.GAME_CONFIG_MANAGER,
    abi: GameConfigManagerAbi,
    functionName: "maximumNumberOfRounds",
  });

  const handleSetCurrentCurrency = (newCurrency: Currency) => {
    setLastUsedCurrency({ game: "flipper", currency: newCurrency });
    setCurrentCurrency(newCurrency);
  };

  const flipCoin = useCallback(async () => {
    const vrfParams = await getVrfParams();
    if (!vrfParams) {
      throw new Error("No VRF params found");
    }
    const vrfFeeBi = vrfParams.vrfFee;
    if (walletClient && publicClient) {
      if (!publicClient) {
        throw new Error("No public client found");
      }

      const [account] = await walletClient.getAddresses();

      const betAmountBi = toDecimals(betAmount);
      const currencyAddress = currentCurrency === "ETH" ? addressesByNetwork.ETH : addressesByNetwork.YOLO;
      const totalWagerString = totalWagerEth.toString();
      const totalWagerBi = toDecimals(totalWagerString);
      const totalValue = currentCurrency === "ETH" ? totalWagerBi + vrfFeeBi : vrfFeeBi;
      const stopGainBi = toDecimals(stopOnProfitAmount || "0");
      // contract expects negative value
      const stopLossBi = -toDecimals(stopOnLossAmount || "0");

      const args = [
        Number(numberOfRounds),
        betAmountBi,
        currencyAddress,
        stopGainBi,
        stopLossBi,
        selectedSide === FlipSide.GOLD,
      ] as const;

      const gas = await publicClient.estimateGas({
        to: addressesByNetwork.FLIPPER,
        data: encodeFunctionData({
          abi: FlipperAbi,
          functionName: "play",
          args,
        }),
        value: totalValue,
        account,
      });

      const { request } = await publicClient.simulateContract({
        address: addressesByNetwork.FLIPPER,
        abi: FlipperAbi,
        functionName: "play",
        args,
        gas: multiplyWeiByNumber(gas, CLASSIC_GAMES_GAS_BUFFER),
        value: totalValue,
        account,
      });
      const hash = await walletClient.writeContract(request);
      sendEntryAnalyticsEvent(totalWagerBi, currentCurrency);
      setTransactionHash(hash);
      return hash;
    }
  }, [
    getVrfParams,
    walletClient,
    publicClient,
    betAmount,
    currentCurrency,
    addressesByNetwork.ETH,
    addressesByNetwork.YOLO,
    addressesByNetwork.FLIPPER,
    totalWagerEth,
    stopOnProfitAmount,
    stopOnLossAmount,
    numberOfRounds,
    selectedSide,
    sendEntryAnalyticsEvent,
  ]);

  const { data: currentFlip, refetch: refetchCurrentFlip } = useCurrentCoinFlip(FlipperContract.FLIPPER_V1_BLAST);

  /**
   * If the current flip's status is DRAWING, we need to refetch the current flip until we get the flip results.
   */
  const isPollingActive = flipperStep === FlipperStep.GENERATING_RANDOMNESS;

  useDeclarativeInterval(
    async () => {
      const newResult = await refetchCurrentFlip();
      const isLatestGameIndexed =
        isHash(newResult.data?.drawingTransactionHash) &&
        isHashEqual(newResult.data.drawingTransactionHash, transactionHash);
      if (newResult.data?.status === FlipperStatus.DRAWN && isLatestGameIndexed) {
        setFlipperStep(FlipperStep.FLIPPING);
      }
    },
    isPollingActive ? 3000 : null
  );

  /**
   * If the current flip's status is CANCELLED, reset to INPUT
   */
  const isLatestGameIndexed =
    isHash(currentFlip?.drawingTransactionHash) && isHashEqual(currentFlip.drawingTransactionHash, transactionHash);
  const isTxCanceled = isLatestGameIndexed && currentFlip?.status === FlipperStatus.CANCELLED;
  useEffect(() => {
    if (isTxCanceled && flipperStep !== FlipperStep.INPUT) {
      setFlipperStep(FlipperStep.INPUT);
    }
  }, [isTxCanceled, flipperStep]);

  /**
   * Whenever we reach FINAL_RESULTS, refetch the history.
   */
  useEffect(() => {
    if (previousFlipperStep !== FlipperStep.FINAL_RESULTS && flipperStep === FlipperStep.FINAL_RESULTS) {
      // Refetch the history
      queryClient.invalidateQueries({ queryKey: ["flipperHistory"] });
    }
  }, [flipperStep, previousFlipperStep, queryClient]);

  /**
   * Update global store with the current playing state.
   */
  useEffect(() => {
    const setIsPlaying = useGlobalStore.getState().setIsPlaying;
    const isPlaying = [FlipperStep.GENERATING_RANDOMNESS, FlipperStep.FLIPPING].includes(flipperStep);
    setIsPlaying(isPlaying);
  }, [flipperStep]);

  return {
    betAmount,
    currentCurrency,
    currentFlip: currentFlip || null,
    numberOfRounds,
    flipperStep,
    maxNumberOfRounds: maxNumberOfRounds || 10,
    maxPayout,
    maxPlayAmountPerRoundWei: maxPlayAmountPerRound,
    minPlayAmountPerRoundWei: minPlayAmountPerRound,
    selectedSide,
    stopOnLossAmount,
    stopOnProfitAmount,
    totalWagerEth,
    flipCoin,
    setSelectedSide,
    setBetAmount,
    setCurrentCurrency: handleSetCurrentCurrency,
    setFlipperStep,
    setNumberOfRounds,
    setMaxNumberOfRounds: () => setNumberOfRounds(String(maxNumberOfRounds || 10)),
    setStopOnLossAmount,
    setStopOnProfitAmount,
  };
};

export const useInfiniteFlipperHistory = (
  { filter }: { filter: FilterFlipperInput },
  sort: ClassicGamesHistorySort
) => {
  const { isConnected } = useAccount();
  return useInfiniteQuery<Flipper[], Error, InfiniteData<Flipper[], Pagination>, unknown[], Pagination>({
    queryKey: ["flipperHistory", isConnected, sort, filter.player, filter.contracts],
    queryFn: async ({ pageParam }) => {
      if (!isConnected && sort === ClassicGamesHistorySort.MINE) {
        return [];
      }
      const graphqlSort = getClassicGamesGraphqlSort(sort);
      return getFlipperHistory({
        filter,
        pagination: pageParam,
        sort: graphqlSort,
      });
    },
    getNextPageParam,
    initialPageParam: { first: FLIPPER_PAGINATION_FIRST },
  });
};

interface UseFlipperAnimationParams {
  flipId?: Flipper["id"];
  flipResults?: Flipper["flipResults"];
  setFlipperStep: (status: FlipperStep) => void;
  flipperStep: FlipperStep;
  goToResults: () => void;
}

/**
 * Contains the logic for additional UI state during the FlipperStep.FLIPPING state.
 */
export const useFlipperAnimation = ({ flipId, flipResults, flipperStep, goToResults }: UseFlipperAnimationParams) => {
  const [flipIndex, setFlipIndex] = useState(0);
  const [flipAnimationState, setFlipAnimationState] = useState(FlipAnimationState.IDLE);

  // Reset the flip index when the flipId changes
  useEffect(() => {
    setFlipIndex(0);
    if (flipperStep === FlipperStep.FLIPPING) {
      setFlipAnimationState(FlipAnimationState.VIDEO_PLAYING);
    }
  }, [flipId, flipperStep]);

  const onSingleFlipAnimationComplete = useCallback(async () => {
    setFlipAnimationState(FlipAnimationState.IDLE);
    await sleep(2000);
    const isLastFlip = flipIndex === flipResults!.length - 1;
    if (isLastFlip) {
      goToResults();
      return;
    }
    setFlipIndex(flipIndex + 1);
    setFlipAnimationState(FlipAnimationState.VIDEO_PLAYING);
  }, [flipIndex, flipResults, goToResults]);

  /**
   * For whatever reason, exiting the FLIPPING state should reset the flip animation state.
   */
  useEffect(() => {
    if (flipperStep !== FlipperStep.FLIPPING && flipAnimationState !== FlipAnimationState.IDLE) {
      setFlipAnimationState(FlipAnimationState.IDLE);
    }
  }, [flipperStep, flipAnimationState]);

  return {
    flipIndex,
    flipAnimationState,
    onSingleFlipAnimationComplete,
  };
};
