import { useTranslation } from "next-i18next";
import { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { Flex, Stack, StackProps, TableContainer, Table, Thead, Tr, Tbody } from "@chakra-ui/react";
import { Button, Text } from "@looksrare/uikit";
import { ConnectWalletPrompt, EmptyHistoryTable, SortOptions } from "../../../shared/components";
import { ClassicGamesHistorySort } from "../../../shared/types";
import { useInfiniteFlipperHistory } from "../..";
import { isFlipTotalWin } from "../../utils";
import { FlipperContract, FlipperStatus } from "../../types";
import { Row, StyledTh } from "./shared";

const hideOnMobile = {
  base: "none",
  lg: "table-cell",
};

export const RecentFlipsTable = (props: StackProps) => {
  const { t } = useTranslation();
  const { address: connectedAddress, isConnected } = useAccount();
  const [sort, setSort] = useState(ClassicGamesHistorySort.ALL);
  const filter = useMemo(() => {
    return {
      player: sort === ClassicGamesHistorySort.MINE ? (connectedAddress as string) : undefined,
      contracts: [FlipperContract.FLIPPER_V1_BLAST],
    };
  }, [connectedAddress, sort]);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isSuccess: isHistoryQuerySuccess,
  } = useInfiniteFlipperHistory({ filter }, sort);
  const rows = data?.pages?.flat() || [];

  return (
    <Stack spacing={4} width="100%" {...props}>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "flex-start", lg: "center" }}
        flexDirection={{ base: "column", lg: "row" }}
        gap={4}
      >
        <Text textStyle="heading-03" bold>
          {t("flipper::Recent Flips")}
        </Text>
        <SortOptions sort={sort} setSort={setSort} />
      </Flex>

      <TableContainer bg="ui-01" borderRadius="container" border="1px solid" borderColor="border-01">
        <Table>
          <Thead>
            <Tr>
              <StyledTh textProps={{ textAlign: "left" }}>{t("flipper::Player")}</StyledTh>
              <StyledTh display={hideOnMobile} textProps={{ textAlign: "left" }}>
                {t("flipper::Selection")}
              </StyledTh>
              <StyledTh>{t("flipper::Flips")}</StyledTh>
              <StyledTh>{t("flipper::Entry")}</StyledTh>
              <StyledTh>{t("flipper::Payout")}</StyledTh>
              <StyledTh display={hideOnMobile}>{t("flipper::Points")}</StyledTh>
              <StyledTh display={hideOnMobile}>{t("flipper::Date")}</StyledTh>
              <StyledTh>{t("flipper::Verify")}</StyledTh>
            </Tr>
          </Thead>
          <Tbody>
            {(rows || []).map((row) => {
              const result = (() => {
                const hasResult = row.status === FlipperStatus.DRAWN;
                if (!hasResult) {
                  return "-";
                }
                const isTotalWin = isFlipTotalWin(
                  (row.flipResults || []).slice(0, row.numberOfRoundsPlayed),
                  row.pickSide
                );

                return isTotalWin ? "Win" : "Loss";
              })();

              return (
                <Row
                  key={row.id.toString()}
                  address={row.player.address}
                  avatarSrc={row.player.avatar?.image?.src}
                  selection={row.pickSide}
                  flipCount={row.numberOfRoundsPlayed}
                  result={result}
                  playAmount={row.playAmountWei}
                  winAmount={row.winningAmountWei}
                  currency={row.currency}
                  date={row.createdAt}
                  points={row.points}
                  drawnTransactionHash={row.drawnTransactionHash}
                  drawingTransactionHash={row.drawingTransactionHash}
                  flipResults={row.flipResults}
                  amountPerRound={row.amountPerRoundWei}
                />
              );
            })}
          </Tbody>
        </Table>
        {(() => {
          if (!isConnected && sort === ClassicGamesHistorySort.MINE) {
            return (
              <Flex width="100%" justifyContent="center">
                <ConnectWalletPrompt py={12} px={4} width="100%" />
              </Flex>
            );
          }
          if (isConnected && isHistoryQuerySuccess && rows.length === 0) {
            return (
              <Flex width="100%" justifyContent="center">
                <EmptyHistoryTable py={12} px={4} width="100%" />
              </Flex>
            );
          }
          if (!!hasNextPage) {
            return (
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                py={3}
                borderTop="1px solid"
                borderColor="border-01"
              >
                <Button variant="outline" colorScheme="secondary" onClick={() => fetchNextPage()}>
                  {t("flipper::Load More")}
                </Button>
              </Flex>
            );
          }
        })()}
      </TableContainer>
    </Stack>
  );
};
