import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Box, Flex, FlexProps, Th as ChakraTh, BoxProps, Tr, Td } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { Address, zeroAddress } from "viem";
import { format } from "date-fns/format";
import { BigIntish, divideWeiByNumber, isAddressEqual } from "@looksrare/utils";
import {
  EthTokenIcon,
  LazyLoad,
  ProfileAvatar,
  Text,
  TextProps,
  TokenYoloIcon,
  UsernameDisplay,
} from "@looksrare/uikit";
import { FlipSide, Flipper } from "../../types";
import { Coin } from "../Coin";
import { getChainId, getFormattedAmount } from "../../../shared/utils";
import { TransactionHashMenu } from "../../../shared/components";

interface StyledThProps extends BoxProps {
  textProps?: TextProps;
}
export const StyledTh = ({ children, textProps, ...props }: StyledThProps) => (
  <ChakraTh {...props}>
    <Text textStyle="helper" bold textAlign="right" color="text-03" {...textProps}>
      {children}
    </Text>
  </ChakraTh>
);

const hideOnMobile = {
  base: "none",
  lg: "table-cell",
};

interface RowProps extends FlexProps {
  address: string;
  avatarSrc?: string;
  selection: FlipSide | null;
  flipCount: number;
  result: "Win" | "Loss" | "-";
  playAmount: BigIntish;
  currency: string;
  winAmount?: BigIntish | null;
  date: string;
  isMobile?: boolean;
  points?: string | null;
  drawnTransactionHash?: string | null;
  drawingTransactionHash?: string | null;
  flipResults: Flipper["flipResults"];
  amountPerRound: Flipper["amountPerRoundWei"];
}

export const Row = ({
  address,
  avatarSrc,
  selection,
  flipCount,
  result,
  playAmount,
  winAmount,
  date,
  currency,
  points,
  drawnTransactionHash,
  drawingTransactionHash,
  flipResults,
  amountPerRound,
  ...props
}: RowProps) => {
  const { t } = useTranslation();
  const { address: connectedAddress } = useAccount();
  const isUserRow = isAddressEqual(address, connectedAddress);
  const [isExpanded, setIsExpanded] = useState(false);

  const isWin = result === "Win";
  const numberOfWins = flipResults?.reduce((acc, flipResult) => (flipResult === selection ? acc + 1 : acc), 0) || 0;
  const winAmountPerWin = numberOfWins ? divideWeiByNumber(winAmount || 0, numberOfWins) : 0n;

  const TokenIcon = currency === zeroAddress ? EthTokenIcon : TokenYoloIcon;

  return (
    <>
      <LazyLoad
        onClick={() => setIsExpanded(!isExpanded)}
        cursor="pointer"
        as={Tr}
        borderLeft="4px solid"
        borderLeftColor={isUserRow ? "interactive-03" : "transparent"}
        alignItems="center"
        _hover={{
          bg: "border-01",
        }}
        _notLast={{ borderBottom: "1px solid", borderBottomColor: "border-01" }}
        sx={{
          "& > td": {
            textAlign: "right",
          },
          "& > td:first-child": {
            textAlign: "left",
          },
        }}
        {...props}
      >
        <Td>
          <Flex alignItems="center">
            <ProfileAvatar
              src={avatarSrc}
              address={address as Address}
              size={{ base: 24, md: 40 }}
              mr={{ base: 2, md: 4 }}
            />

            {isUserRow ? (
              <Text color="text-01" bold>
                {t("flipper::You")}
              </Text>
            ) : (
              <UsernameDisplay
                address={address as Address}
                textStyle={{ base: "detail", lg: "body" }}
                bold
                noOfLines={1}
              />
            )}
          </Flex>
        </Td>
        <Td display={hideOnMobile}>
          <Flex gap={1} alignItems="center">
            <Box width="24px" height="24px">
              <Coin opacity={1} border={0} p={0} variant={selection === FlipSide.GOLD ? "gold" : "silver"} />
            </Box>
            <Text textStyle="detail" color="text-03">
              {selection === FlipSide.GOLD ? "Gold" : "Silver"}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Text textStyle="detail" color="text-03">
            {flipCount}
          </Text>
        </Td>
        <Td>
          <Flex gap={1} justifyContent="flex-end" alignItems="center">
            <Text textStyle="detail">{getFormattedAmount(playAmount)}</Text>
            <TokenIcon boxSize={4} />
          </Flex>
        </Td>
        <Td>
          <Flex gap={1} justifyContent="flex-end" alignItems="center">
            <Text textStyle="detail" color={isWin ? "link-01" : "text-03"}>
              {getFormattedAmount(winAmount)}
            </Text>
            {isWin && <TokenIcon boxSize={4} />}
          </Flex>
        </Td>
        <Td display={hideOnMobile}>
          <Flex gap={1} justifyContent="flex-end">
            <Text textStyle="detail" color="text-03">
              {getFormattedAmount(points, { fromDecimalsOptions: { decimals: 0 } })}
            </Text>
          </Flex>
        </Td>

        <Td display={hideOnMobile}>
          <Flex flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
            <Text textStyle="helper" color="text-03" textAlign="right">
              {format(date, "HH:mm")}
            </Text>
            <Text textStyle="helper" color="text-03" textAlign="right">
              {format(date, "MM/dd/yyyy")}
            </Text>
          </Flex>
        </Td>

        <Td>
          <TransactionHashMenu
            drawnTransactionHash={drawnTransactionHash}
            drawingTransactionHash={drawingTransactionHash}
            chainId={getChainId()}
          />
        </Td>
      </LazyLoad>
      {isExpanded &&
        (flipResults || []).map((flipResult, flipIndex) => {
          if (!flipResults) {
            return null;
          }
          const isRowWin = flipResult === selection;
          return (
            <Tr
              key={flipResult + flipIndex}
              bg="ui-bg"
              sx={{
                "& > td": {
                  textAlign: "right",
                },
              }}
            >
              <Td />
              <Td display={hideOnMobile} />
              <Td>
                <Text textStyle="detail" color="text-03">
                  {flipIndex + 1}/{flipResults.length}
                </Text>
              </Td>
              <Td>
                <Flex gap={1} justifyContent="flex-end" alignItems="center">
                  <Text textStyle="detail">{getFormattedAmount(amountPerRound)}</Text>
                  <TokenIcon boxSize={4} />
                </Flex>
              </Td>
              <Td>
                <Flex gap={1} justifyContent="flex-end" alignItems="center">
                  <Text textStyle="detail" color={isRowWin ? "link-01" : "text-03"}>
                    {isRowWin ? getFormattedAmount(winAmountPerWin) : "-"}
                  </Text>
                  {isRowWin && <TokenIcon boxSize={4} />}
                </Flex>
              </Td>
              <Td display={hideOnMobile} />
              <Td display={hideOnMobile} />
              <Td />
            </Tr>
          );
        })}
    </>
  );
};
