import { useTranslation } from "next-i18next";
import { InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { Button, NumberInput, Text } from "@looksrare/uikit";

interface NumberOfFlipsInputProps {
  numberOfRounds: string;
  setNumberOfRounds: (value: string) => void;
  maxNumberOfRounds: number;
  onClickMax: () => void;
  inputWarning?: string;
}

export const NumberOfFlipsInput = ({
  numberOfRounds,
  setNumberOfRounds,
  maxNumberOfRounds,
  onClickMax,
  inputWarning,
}: NumberOfFlipsInputProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Text textStyle="detail" bold color="text-03" flexGrow={1}>
        {t("flipper::Number of Flips")}
      </Text>
      <InputGroup size="md">
        <NumberInput
          size="md"
          value={numberOfRounds}
          // ensure only numbers 1+ are entered
          onTextChange={(value) => {
            let safeValue = value;
            if (isNaN(Number(value)) || Number(value) < 1 || value.indexOf(".") !== -1) {
              safeValue = "";
            }
            setNumberOfRounds(safeValue);
          }}
        />
        <InputRightElement width="fit-content" pr={2}>
          <Button
            colorScheme="secondary"
            size="xs"
            onClick={onClickMax}
            isDisabled={Number(numberOfRounds) === maxNumberOfRounds}
          >
            {t("flipper::Max")}
          </Button>
        </InputRightElement>
      </InputGroup>
      {!!inputWarning && (
        <Text textStyle="helper" color="text-error">
          {inputWarning}
        </Text>
      )}
    </Stack>
  );
};
