import { Context, useContext } from "react";
import { GameContext, GameProviderContext } from "@looksrare/uikit";
import { type Currency } from "@looksrare/config";

export interface FlipperConfig {
  isMuted: boolean;
  isWalletBlocked: boolean;
  supportedCurrencies: Currency[];
  selfTimeoutUntil?: string | null;
}

export const useFlipperConfig = () => {
  const context = useContext(GameProviderContext as Context<GameContext<FlipperConfig> | undefined>);
  if (!context?.config) {
    throw new Error("No config found");
  }

  return context.config;
};
