import { Flex, Stack } from "@chakra-ui/react";
import { SelfExclusionModal, useWidgetBotOptions } from "@looksrare/uikit";
import { useWidgetBotCrate } from "@looksrare/utils";
import { GameArea } from "../components/GameArea";
import { RecentFlipsTable } from "../components/History/RecentFlipsTable";
import { useFlipperConfig } from "..";

export const FlipperView = () => {
  const { isMuted, selfTimeoutUntil } = useFlipperConfig();
  useWidgetBotCrate(useWidgetBotOptions());

  return (
    <>
      <Stack spacing={8}>
        <GameArea />
        <Flex width="100%" justifyContent="center">
          <RecentFlipsTable maxWidth="1600px" px={8} />
        </Flex>
        <audio src="/sounds/flipper/bg_track.mp3" autoPlay loop muted={isMuted} />
      </Stack>
      {!!selfTimeoutUntil && <SelfExclusionModal endDate={selfTimeoutUntil} />}
    </>
  );
};
