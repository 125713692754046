import { Divider, Flex, HStack, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { Accordion, AccordionIcon, EthTokenIcon, NumberInput, Text, TokenYoloIcon } from "@looksrare/uikit";
import { formatCompactNumber, useCoinPrices } from "@looksrare/utils";
import { useTranslation } from "next-i18next";

interface AdvancedInputsProps {
  currencySymbol: Currency;
  stopOnProfitAmount: string;
  setStopOnProfitAmount: (value: string) => void;
  stopOnLossAmount: string;
  setStopOnLossAmount: (value: string) => void;
}

export const AdvancedInputs = ({
  currencySymbol,
  stopOnProfitAmount,
  setStopOnProfitAmount,
  stopOnLossAmount,
  setStopOnLossAmount,
}: AdvancedInputsProps) => {
  const { t } = useTranslation();

  const coinsPricesQuery = useCoinPrices();
  const ethUsdPrice = coinsPricesQuery.data?.eth.price ?? 0;

  const stopOnProfitAmountFloat = stopOnProfitAmount ? parseFloat(stopOnProfitAmount) : 0;
  const stopOnProfitDollarValue = stopOnProfitAmountFloat * ethUsdPrice;

  const stopOnLossAmountFloat = stopOnLossAmount ? parseFloat(stopOnLossAmount) : 0;
  const stopOnLossDollarValue = stopOnLossAmountFloat * ethUsdPrice;

  const isEth = currencySymbol === "ETH";
  const TokenIcon = isEth ? EthTokenIcon : TokenYoloIcon;

  return (
    <Accordion
      background="none"
      buttonProps={{
        p: 0,
        border: "none",
      }}
      accordionItemProps={{
        border: "none",
      }}
      panelProps={{
        p: 0,
        pt: 4,
      }}
      buttonContent={
        <Flex gap={1} width="100%" alignItems="center">
          <Text textStyle="detail" fontWeight="400">
            {t("quantum::Advanced")}
          </Text>
          <AccordionIcon />
          <Divider flexGrow={1} borderColor="border-01" width="100%" />
        </Flex>
      }
      showAccordionIcon={false}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <HStack justifyContent="space-between">
            <Text textStyle="detail" color="text-03" bold>
              {t("quantum::Stop on Profit")}
            </Text>
            {isEth && (
              <Text textStyle="helper" color="text-03">
                {`$${formatCompactNumber(stopOnProfitDollarValue, undefined, undefined, undefined, 1_000_000)}`}
              </Text>
            )}
          </HStack>

          <InputGroup>
            <InputLeftElement height="48px">
              <TokenIcon boxSize={5} />
            </InputLeftElement>

            <NumberInput value={stopOnProfitAmount} onTextChange={setStopOnProfitAmount} height="48px" pl={10} />
          </InputGroup>
        </Stack>

        <Stack spacing={1}>
          <HStack justifyContent="space-between">
            <Text textStyle="detail" color="text-03" bold>
              {t("quantum::Stop on Loss")}
            </Text>

            {isEth && (
              <Text textStyle="helper" color="text-03">
                {`$${formatCompactNumber(stopOnLossDollarValue, undefined, undefined, undefined, 1_000_000)}`}
              </Text>
            )}
          </HStack>

          <InputGroup>
            <InputLeftElement height="48px">
              <TokenIcon boxSize={5} />
            </InputLeftElement>

            <NumberInput value={stopOnLossAmount} onTextChange={setStopOnLossAmount} height="48px" pl={10} />
          </InputGroup>
        </Stack>
      </Stack>
    </Accordion>
  );
};
