import { useEffect, useState } from "react";
import { Hash } from "viem";
import { BoxProps, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { usePublicClient, useWalletClient } from "wagmi";
import { ChainId, DEFAULT_ERC20_APPROVAL_AMOUNT, Erc20Abi, IS_TESTNET } from "@looksrare/config";
import {
  Text,
  BulkTransactionStep,
  BulkTransactionStepStatus,
  StepStatus,
  TransactionStepRow,
  StepErrorCta,
} from "@looksrare/uikit";
import { BigIntish, useAddressesByNetwork, useHandleModalStep, useWalletClientIsReady } from "@looksrare/utils";

interface ApproveYoloStepProps extends BoxProps {
  status: StepStatus;
  collapse?: boolean;
  isCurrentStep: boolean;
  amountWei: BigIntish;
  onComplete: () => void;
}

export const ApproveYoloStep = ({
  status,
  collapse,
  isCurrentStep,
  onComplete,
  amountWei,
  ...props
}: ApproveYoloStepProps) => {
  const { isSignerReady } = useWalletClientIsReady();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState<Hash>();
  const addressesByNetwork = useAddressesByNetwork(IS_TESTNET ? ChainId.BLAST_SEPOLIA : ChainId.BLAST_MAINNET);
  const transferManagerAddress = addressesByNetwork.TRANSFER_MANAGER_CLASSIC_GAMES;

  const { handleSubmit, isRejected, isIdle, isAccepted } = useHandleModalStep({
    onSubmit: async () => {
      if (walletClient && publicClient) {
        const [account] = await walletClient.getAddresses();

        const erc20ContractInfo = {
          address: addressesByNetwork.YOLO,
          abi: Erc20Abi,
        } as const;

        const allowanceAmount = await publicClient.readContract({
          ...erc20ContractInfo,
          functionName: "allowance",
          args: [account, transferManagerAddress],
        });

        if (allowanceAmount < BigInt(amountWei.toString())) {
          const { request } = await publicClient.simulateContract({
            ...erc20ContractInfo,
            functionName: "approve",
            args: [transferManagerAddress, DEFAULT_ERC20_APPROVAL_AMOUNT],
            account,
          });
          const hash = await walletClient.writeContract(request);
          setTransaction(hash);
          const receipt = await publicClient.waitForTransactionReceipt({ hash });

          if (receipt.status === "success") {
            setTransaction(undefined);
          } else {
            throw new Error(`${receipt.transactionHash} failed`);
          }
        }
        onComplete();
      }
    },
  });

  const rowStatus = ((): BulkTransactionStepStatus => {
    if (isRejected) {
      return "error";
    }
    if (isIdle) {
      return "wait";
    }
    if (isAccepted) {
      return "done";
    }
    return "pending";
  })();

  useEffect(() => {
    if (isCurrentStep && isSignerReady) {
      handleSubmit({ callOnlyOnce: true });
    }
  }, [handleSubmit, isCurrentStep, isSignerReady]);

  const counterDisplay = "(1/1)";

  return (
    <BulkTransactionStep status={status} collapse={collapse} title={t("flipper::YOLO Approval")} {...props}>
      <VStack spacing={4} alignItems="flex-start" width="100%">
        <Text textStyle="helper" bold color={isRejected ? "text-error" : "text-01"}>
          {isRejected
            ? t("flipper::You declined the transaction {{counterDisplay}}", { counterDisplay })
            : t("flipper::Confirm transaction in wallet {{counterDisplay}}", { counterDisplay })}
        </Text>
        <TransactionStepRow transactionHash={transaction} status={rowStatus} text={t("flipper::YOLO")} />
        {isRejected && <StepErrorCta onRetry={() => handleSubmit()} />}
      </VStack>
    </BulkTransactionStep>
  );
};
