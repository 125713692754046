import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Flex, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { Button, NumberInput, Text } from "@looksrare/uikit";
import {
  divideWeiByNumber,
  formatToSignificant,
  useAddressesByNetwork,
  useEthBalance,
  useGlobalStore,
  useTokenBalance,
} from "@looksrare/utils";
import { getChainId } from "../../shared/utils";
import type { SetCurrencyHandler } from "../../shared/types";
import { EntryCurrencyMenu } from "../../shared/components/EntryCurrencyMenu";
import { useFlipperConfig } from "../config";

interface WagerInputProps {
  betAmount: string;
  currentCurrency: Currency;
  inputWarning?: string;
  maxBetAmountWei?: bigint;
  numberOfFlips: number;
  setBetAmount: (value: string) => void;
  setCurrentCurrency: SetCurrencyHandler;
}

export const WagerInput = ({
  betAmount,
  currentCurrency,
  inputWarning,
  maxBetAmountWei,
  numberOfFlips,
  setBetAmount,
  setCurrentCurrency,
}: WagerInputProps) => {
  const { t } = useTranslation();
  const isPlaying = useGlobalStore((state) => state.isPlaying); // Hide balances while a game is running

  // Currency Balances
  const { address } = useAccount();
  const chainId = getChainId();
  const { data: ethBalanceWei } = useEthBalance(address!, { enabled: !!address }, chainId);
  const addressesByNetwork = useAddressesByNetwork();
  const { data: tokenBalanceWei } = useTokenBalance({
    contractAddress: addressesByNetwork.YOLO!,
    address: address!,
    queryOptions: { enabled: !!address },
  });
  const { supportedCurrencies } = useFlipperConfig();

  const maxBetPerRoundWei = divideWeiByNumber(maxBetAmountWei || BigInt(0), numberOfFlips);
  const maxBetFormatted = !!maxBetAmountWei && formatToSignificant(maxBetPerRoundWei, 3, { shouldCommify: false });
  const isDoubleButtonDisabled =
    !betAmount ||
    parseFloat(betAmount) === 0 ||
    (!!maxBetFormatted && parseFloat(betAmount) * 2 > parseFloat(maxBetFormatted));
  const isHalfButtonDisabled = !betAmount || parseFloat(betAmount) / 2 < 0.001;
  const isMaxButtonDisabled =
    !maxBetAmountWei || (!!maxBetFormatted && parseFloat(betAmount) === parseFloat(maxBetFormatted));

  const walletBalanceWei = currentCurrency === "ETH" ? ethBalanceWei : tokenBalanceWei;

  return (
    <Stack spacing={2}>
      <Flex gap={2} alignItems="center">
        <Text textStyle="detail" bold color="text-03" flexGrow={1}>
          {t("Entry")}
        </Text>
        <Text textStyle="helper" color="text-03" layerStyle={isPlaying ? "animatedFocusLoss" : "none"}>
          {!!walletBalanceWei &&
            t("Balance: {{walletBalance}}", { walletBalance: formatToSignificant(walletBalanceWei, 6) })}
        </Text>
      </Flex>
      <InputGroup
        size="md"
        // @note Chakra bug https://github.com/chakra-ui/chakra-ui/issues/7629#issuecomment-1562090539
        sx={{
          isolation: "auto !important",
        }}
      >
        <NumberInput
          size="md"
          value={betAmount}
          onTextChange={(value) => {
            let safeValue = value;
            if (value[0] === ".") {
              safeValue = `0${value}`;
            }
            setBetAmount(safeValue);
          }}
        />
        <InputRightElement width="fit-content" pr={2} opacity={1}>
          <EntryCurrencyMenu
            currencies={supportedCurrencies}
            currentCurrency={currentCurrency}
            onSelectCurrency={setCurrentCurrency}
          />
        </InputRightElement>
      </InputGroup>
      <Flex gap={2} alignItems="center">
        <Button
          variant="outline"
          colorScheme="secondary"
          size="xxs"
          onClick={() => {
            const half = Number(betAmount) / 2 || 0;
            setBetAmount(`${half}`);
          }}
          isDisabled={isHalfButtonDisabled}
          flex={1}
        >
          ½
        </Button>
        <Button
          variant="outline"
          colorScheme="secondary"
          size="xxs"
          onClick={() => {
            const double = Number(betAmount) * 2 || 0;
            setBetAmount(`${double}`);
          }}
          isDisabled={isDoubleButtonDisabled}
          flex={1}
        >
          x2
        </Button>
        <Button
          variant="outline"
          colorScheme="secondary"
          size="xxs"
          onClick={() => {
            if (!maxBetFormatted) {
              return;
            }

            setBetAmount(maxBetFormatted);
          }}
          isDisabled={isMaxButtonDisabled}
          flex={1}
        >
          {t("flipper::Max")}
        </Button>
      </Flex>
      {!!inputWarning && (
        <Text textStyle="helper" color="text-error">
          {inputWarning}
        </Text>
      )}
    </Stack>
  );
};
