import { getTopicName, TopicName, useRealtimeSubscription } from "@looksrare/utils";

export const useFlipperPageViews = () => {
  const topicName = getTopicName({ name: TopicName.flipperActiveViews });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useFlipperActivePlayers = () => {
  const topicName = getTopicName({ name: TopicName.flipperActivePlayers });
  return useRealtimeSubscription<number>(topicName);
};
